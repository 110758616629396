// src/EmailForm.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FaGoogle, FaFacebook, FaEye, FaEyeSlash } from 'react-icons/fa';

function EmailForm() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const checkEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/check-email', { email });
      if (response.data.exists) {
        setStep(2);
      } else {
        setStep(3);
      }
    } catch (error) {
      console.error(error);
      setMessage('Chyba při ověřování e-mailu. Zkuste to prosím znovu.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      await login(email, formData.password);
      setMessage('Přihlášení proběhlo úspěšně!');
      navigate('/my-surveys');
    } catch (error) {
      console.error(error);
      setMessage(
        error.response?.data?.message ||
          'Chyba při přihlášení. Zkontrolujte své údaje.'
      );
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/register', {
        name: formData.name,
        email,
        password: formData.password,
      });
      
      // Automatically log in the user and navigate to the survey creation page
      await login(email, formData.password);
      navigate('/surveys');
    } catch (error) {
      console.error(error);
      setMessage(
        error.response?.data?.message ||
          'Chyba při registraci. Zkuste to prosím znovu.'
      );
    }
  };

  const socialLogin = (provider) => {
    window.location.href = `https://subwaysurveys-2s9pj.ondigitalocean.app/${provider}`;
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100 dark:bg-gray-900 pt-8">
      {/* Logo Section */}
      <div className="flex justify-center mb-6 mt-4">
        <img src="/ss.png" alt="Logo" className="h-32 w-auto" />
      </div>

      {/* Form Container */}
      <div className="bg-white dark:bg-gray-800 p-8 rounded shadow-md w-full max-w-md border-t-4 border-accentYellow">
        {message && (
          <div className="mb-4 text-center text-red-500">{message}</div>
        )}

        {step === 1 && (
          <form onSubmit={checkEmail}>
            <h2 className="text-2xl font-bold mb-6 text-center text-black dark:text-white">
              Zadejte svůj e-mail
            </h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-accentYellow text-gray-800 dark:bg-accentYellow-dark py-2 rounded transition duration-200
              hover:bg-accentYellow-hover dark:hover:bg-accentYellow-hover"
            >
              Pokračovat
            </button>

            <div className="flex items-center my-6">
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-600" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">
                nebo se přihlaste
              </span>
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-600" />
            </div>

            <div className="flex flex-col space-y-4">
              <button
                type="button"
                onClick={() => socialLogin('google')}
                className="flex items-center justify-center w-full py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-black dark:text-white
                hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-200"
              >
                <FaGoogle className="mr-2" />
                Přihlásit se pomocí Google
              </button>
              <button
                type="button"
                onClick={() => socialLogin('facebook')}
                className="flex items-center justify-center w-full py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-black dark:text-white
                hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-200"
              >
                <FaFacebook className="mr-2" />
                Přihlásit se pomocí Facebook
              </button>
            </div>
          </form>
        )}

        {(step === 2 || step === 3) && (
          <form onSubmit={step === 2 ? loginUser : registerUser}>
            <h2 className="text-2xl font-bold mb-6 text-center text-black dark:text-white">
              {step === 2 ? 'Přihlášení' : 'Registrace'}
            </h2>

            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white cursor-pointer
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                value={email}
                onClick={() => {
                  setStep(1);
                }}
                readOnly
              />
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                Klikněte na e-mail pro změnu
              </p>
            </div>

            {step === 3 && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 mb-2"
                  htmlFor="name"
                >
                  Jméno
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                  focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            <div className="mb-6 relative">
              <label
                className="block text-gray-700 dark:text-gray-300 mb-2"
                htmlFor="password"
              >
                Heslo
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-black dark:text-white
                focus:outline-none focus:border-accentYellow dark:focus:border-accentYellow-dark pr-10"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 dark:text-gray-300 transform translate-y-4"
                aria-label="Toggle password visibility"
              >
                {showPassword ? <FaEyeSlash size={22} /> : <FaEye size={22} />}
              </button>
            </div>


            <button
              type="submit"
              className="w-full bg-accentYellow text-gray-800 dark:bg-accentYellow-dark py-2 rounded transition duration-200
              hover:bg-accentYellow-hover dark:hover:bg-accentYellow-hover"
            >
              {step === 2 ? 'Přihlásit se' : 'Registrovat se'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default EmailForm;
