import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ children }) {
  const { token } = useContext(AuthContext); 

  if (!token) {
    // Uživatel není přihlášen
    return <Navigate to="/auth" replace />;
  }

  // Uživatel je přihlášen, zobrazíme požadovanou komponentu
  return children;
}

export default ProtectedRoute;
