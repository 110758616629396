// client/src/Surveys.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { FaTimes, FaPlus } from 'react-icons/fa'; // Import ikon
import { useNavigate } from 'react-router-dom';

function Surveys() {
  const { token, user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(''); // Popis ankety
  const [addDescription, setAddDescription] = useState(false); // Přepínač pro přidání popisu
  const [options, setOptions] = useState(['', '']); // Minimálně dvě možnosti
  const [message, setMessage] = useState('');
  const [allowMultipleSelections, setAllowMultipleSelections] = useState(false);
  const [selectionType, setSelectionType] = useState('unlimited'); // 'unlimited' nebo 'specify'
  const [specifyNumber, setSpecifyNumber] = useState(1);
  const [votingRestriction, setVotingRestriction] = useState('browser');
  const navigate = useNavigate();

  // Funkce pro změnu možnosti
  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  // Funkce pro přidání nové možnosti
  const addOption = () => {
    setOptions([...options, '']);
  };

  // Funkce pro odstranění možnosti
  const removeOption = (index) => {
    if (options.length > 2) {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    }
  };

  // Funkce pro vytvoření ankety
  const createSurvey = async (e) => {
    e.preventDefault();

    // Ensure that each option is non-empty
    const filteredOptions = options.filter(option => option.trim() !== '');

    if (filteredOptions.length < 2) {
      setMessage("Musíte zadat alespoň dvě možnosti.");
      return;
    }

    try {
      const payload = {
        title,
        options: filteredOptions,
        allowMultipleSelections,
        voteLimit: allowMultipleSelections
          ? selectionType === 'specify'
            ? Number(specifyNumber)
            : null
          : 1,
        votingRestriction,
        description: addDescription && description.trim() !== '' ? description.trim() : null,
      };

      const response = await axios.post('/api/surveys', payload, { // Relativní URL
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const surveySlug = response.data.survey?.slug;
      if (surveySlug) {
        navigate(`/vote/${surveySlug}`);
      } else {
        setMessage("Nepodařilo se získat identifikátor ankety.");
      }
    } catch (error) {
      console.error("Error creating survey:", error);
      setMessage(error.response?.data?.message || "Chyba při vytváření ankety.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 px-4 py-8">
      {/* Nadpis */}
      <div className="max-w-[700px] mx-auto mb-8">
        <h1 className="text-4xl font-extrabold text-center text-black dark:text-white">Tvorba ankety</h1>
        <p className="text-center text-gray-600 dark:text-gray-300 mt-2">
          Vyplňte všechny údaje pro vytvoření ankety
        </p>
      </div>

      {/* Formulář */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mx-auto max-w-[700px] border-t-4 border-accentYellow">
        {message && (
          <div
            className={`mb-4 text-center ${
              message.includes('úspěšně') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </div>
        )}
        <form onSubmit={createSurvey}>
          {/* Název ankety */}
          <div className="mb-6">
            <label className="block text-black dark:text-white mb-2" htmlFor="title">
              Název ankety
            </label>
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Zadejte otázku"
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          {/* Přepínač pro přidání popisu */}
          <div className="mb-8 flex items-center">
            <span className="text-black dark:text-white mr-3">Přidat popis ankety</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={addDescription}
                onChange={() => setAddDescription(!addDescription)}
                className="sr-only"
              />
              <div
                className={`w-12 h-6 rounded-full transition-colors duration-300 ease-in-out ${
                  addDescription ? 'bg-accentYellow dark:bg-accentYellow-dark' : 'bg-gray-300 dark:bg-gray-600'
                }`}
              >
                <div
                  className={`absolute top-0.5 left-0.5 bg-white h-5 w-5 rounded-full transition-transform duration-300 ease-in-out ${
                    addDescription ? 'translate-x-6' : ''
                  }`}
                ></div>
              </div>
            </label>
          </div>

          {/* Popis ankety */}
          {addDescription && (
            <div className="mb-6">
              <label className="block text-black dark:text-white mb-2" htmlFor="description">
                Popis ankety
              </label>
              <textarea
                name="description"
                id="description"
                placeholder="Zadejte popis ankety"
                className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
              ></textarea>
            </div>
          )}

          {/* Možnosti */}
          <div className="mb-4">
            <label className="block text-black dark:text-white mb-2">Možnosti</label>
            {options.map((option, index) => (
              <div key={index} className="relative flex items-center mb-4">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  placeholder={`Možnost ${index + 1}`}
                  className="flex-grow p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow pr-12"
                  required
                />
                {options.length > 2 && (
                  <button
                    type="button"
                    onClick={() => removeOption(index)}
                    className="absolute right-3 text-red-500 hover:text-red-700 focus:outline-none"
                    title="Odstranit možnost"
                    aria-label={`Odstranit možnost ${index + 1}`}
                  >
                    <FaTimes size={20} />
                  </button>
                )}
              </div>
            ))}
            <div className="flex justify-start">
              <button
                type="button"
                onClick={addOption}
                className="mt-2 bg-accentYellow text-gray-800 py-2 px-4 rounded-lg hover:bg-accentYellow-hover transition duration-200 flex items-center"
              >
                <FaPlus className="mr-2" />
                Přidat možnost
              </button>
            </div>
          </div>

          {/* Dělící čára */}
          <hr className="my-4 border-t border-gray-300 dark:border-gray-600" />

          {/* Nastavení ankety */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4 text-black dark:text-white">Nastavení ankety</h2>

            {/* Přepínač pro povolení více výběrů */}
            <div className="mb-4 flex items-center">
              <span className="text-black dark:text-white mr-3">Umožnit výběr více možností</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={allowMultipleSelections}
                  onChange={(e) => setAllowMultipleSelections(e.target.checked)}
                  className="sr-only"
                />
                <div
                  className={`w-12 h-6 rounded-full transition-colors duration-300 ease-in-out ${
                    allowMultipleSelections ? 'bg-accentYellow dark:bg-accentYellow-dark' : 'bg-gray-300 dark:bg-gray-600'
                  }`}
                >
                  <div
                    className={`absolute top-0.5 left-0.5 bg-white h-5 w-5 rounded-full transition-transform duration-300 ease-in-out ${
                      allowMultipleSelections ? 'translate-x-6' : ''
                    }`}
                  ></div>
                </div>
              </label>
            </div>

            {/* Typ omezení výběru */}
            {allowMultipleSelections && (
              <div className="mb-4">
                <label className="block text-black dark:text-white mb-2" htmlFor="selectionType">
                  Typ omezení výběru
                </label>
                <select
                  name="selectionType"
                  id="selectionType"
                  value={selectionType}
                  onChange={(e) => setSelectionType(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow"
                >
                  <option value="unlimited">Neomezeně</option>
                  <option value="specify">Specifikovat počet</option>
                </select>
              </div>
            )}

            {/* Počet možností */}
            {allowMultipleSelections && selectionType === 'specify' && (
              <div className="mb-4">
                <label className="block text-black dark:text-white mb-2" htmlFor="specifyNumber">
                  Počet možností, které může uživatel vybrat
                </label>
                <input
                  type="number"
                  name="specifyNumber"
                  id="specifyNumber"
                  min="1"
                  value={specifyNumber}
                  onChange={(e) => setSpecifyNumber(e.target.value)}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow"
                  required
                />
              </div>
            )}
          </div>

          {/* Omezení hlasování */}
          <div className="mb-6">
            <label className="block text-black dark:text-white mb-2" htmlFor="votingRestriction">
              Omezení hlasování
            </label>
            <select
              name="votingRestriction"
              id="votingRestriction"
              value={votingRestriction}
              onChange={(e) => setVotingRestriction(e.target.value)}
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-accentYellow"
            >
              <option value="unlimited">Uživatelé mohou hlasovat kolikrát chtějí</option>
              <option value="browser">Omezit hlasování na relaci prohlížeče</option>
              <option value="ip">Omezit hlasování na IP adresu</option>
            </select>
          </div>

          {/* Tlačítko pro vytvoření ankety */}
          <button
            type="submit"
            className="w-full bg-accentYellow text-gray-800 py-3 rounded-lg hover:bg-accentYellow-hover transition duration-200 text-lg font-semibold"
          >
            Vytvořit anketu
          </button>
        </form>
      </div>
    </div>
  );
}

export default Surveys;
