// src/App.js

import React, { useContext, useState } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import EmailForm from './EmailForm';
import Surveys from './Surveys';
import SurveyList from './SurveyList';
import Vote from './Vote';
import Results from './Results';
import ProtectedRoute from './ProtectedRoute';
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';

function App() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<EmailForm />} />
        <Route
          path="/surveys"
          element={
            <ProtectedRoute>
              <Surveys />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-surveys"
          element={
            <ProtectedRoute>
              <SurveyList />
            </ProtectedRoute>
          }
        />
        <Route path="/vote/:slug" element={<Vote />} />
        <Route path="/results/:slug" element={<Results />} />
      </Routes>
    </div>
  );
}

function Navigation() {
  const { token, logout } = useContext(AuthContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="bg-white dark:bg-gray-800 text-black dark:text-white shadow-md relative">
      <div className="container mx-auto px-4 py-3 max-w-[900px] flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img src="/ss.png" alt="Logo" className="h-12 w-auto mr-2" />
        </Link>

        {/* Desktop Navigation Links and Theme Toggle */}
        <div className="hidden md:flex items-center space-x-6">
          {token ? (
            <>
              <Link to="/surveys" className="hover-underline">
                Vytvořit anketu
              </Link>
              <Link to="/my-surveys" className="hover-underline">
                Moje Ankety
              </Link>
              <button onClick={handleLogout} className="hover-underline">
                Odhlásit se
              </button>
            </>
          ) : (
            <Link to="/auth" className="hover-underline">
              Přihlášení/Registrace
            </Link>
          )}
          <button
            onClick={toggleTheme}
            className="ml-4 focus:outline-none"
            aria-label="Přepnout mód"
          >
            {theme === 'light' ? (
              <FaSun size={24} className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180" />
            ) : (
              <FaMoon size={24} className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180" />
            )}
          </button>
        </div>

        {/* Mobile Theme Toggle and Hamburger Icon */}
        <div className="flex items-center space-x-4 md:hidden">
          <button
            onClick={toggleTheme}
            className="focus:outline-none"
            aria-label="Toggle Theme"
          >
            {theme === 'light' ? (
              <FaSun size={24} className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180" />
            ) : (
              <FaMoon size={24} className="text-yellow-500 transition-transform duration-300 transform hover:rotate-180" />
            )}
          </button>
          <button
            onClick={toggleMenu}
            className="focus:outline-none"
            aria-label="Toggle Menu"
          >
            {menuOpen ? (
              <FaTimes size={24} className="text-gray-800 dark:text-gray-100" />
            ) : (
              <FaBars size={24} className="text-gray-800 dark:text-gray-100" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          menuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } md:hidden absolute inset-x-0 bg-white dark:bg-gray-800 text-black dark:text-white space-y-4 p-4 transition-all duration-300 ease-in-out z-10 overflow-hidden`}
      >
        {token ? (
          <>
            <Link
              to="/surveys"
              className="block hover-underline"
              onClick={toggleMenu}
            >
              Vytvořit anketu
            </Link>
            <Link
              to="/my-surveys"
              className="block hover-underline"
              onClick={toggleMenu}
            >
              Moje Ankety
            </Link>
            <button
              onClick={() => {
                handleLogout();
                toggleMenu();
              }}
              className="block hover-underline"
            >
              Odhlásit se
            </button>
          </>
        ) : (
          <Link to="/auth" className="block hover-underline" onClick={toggleMenu}>
            Přihlášení/Registrace
          </Link>
        )}
      </div>
    </nav>
  );
}

function Home() {
  return (
    <div className="container mx-auto p-8 bg-white dark:bg-gray-900">
      <h1 className="text-4xl font-bold text-center text-black dark:text-white">
        Vítejte na domovské stránce
      </h1>
    </div>
  );
}

export default App;
