// client/src/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Vytvoření AuthContext
export const AuthContext = createContext();

// AuthProvider komponenta
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [user, setUser] = useState(null);

  // Funkce pro ukládání tokenu
  const storeToken = (newToken) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem('token', newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      console.log('Token stored and Authorization header set:', newToken);
    } else {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      console.log('Token removed and Authorization header deleted.');
    }
  };

  // Načítání uživatelských dat při změně tokenu
  useEffect(() => {
    if (token) {
      console.log('Fetching user with token:', token);
      axios.get('/api/user', { // Použijte relativní URL
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        console.log('User data fetched:', response.data.user);
        setUser(response.data.user);
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        storeToken('');
        setUser(null);
      });
    } else {
      setUser(null);
    }
  }, [token]);

  // Funkce pro přihlášení
  const login = async (email, password) => {
    try {
      console.log('Attempting to log in with email:', email);
      const response = await axios.post('/api/login', { email, password }); // Relativní URL
      console.log('Login response:', response.data);
      storeToken(response.data.token);
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Funkce pro odhlášení
  const logout = () => {
    console.log('Logging out.');
    storeToken('');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
